<template>
  <v-form ref="form" v-model="is_valid">
    <v-container fluid class="px-6">
      <v-tabs
        class="mt-6"
        v-model="tab"
        background-color="deep-purple accent-4"
        center-active
        dark
      >
        <v-tab href="#general-info">{{ $t("products.general_info") }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item :key="1" :value="'general-info'">
          <div class="d-flex flex-column flex-md-row" style="gap: 150px">
            <v-flex xs6>
              <h4 class="text-h4 mb-7 mt-3">Required Information</h4>

              <v-text-field
                outlined
                clearable
                dense
                label="Company Name *"
                v-model="provider.name"
                :rules="[rules.required]"
              />
              <v-autocomplete
                dense
                outlined
                :label="$t('shelf_*')"
                :items="shelves"
                v-model="provider.category_ids"
                item-value="id"
                item-text="name"
                :loading="isLoadingShelves"
                :rules="[rules.not_empty]"
                multiple
                chips
              />
              <v-text-field
                outlined
                clearable
                dense
                :label="$t('address_*')"
                :rules="[rules.required]"
                v-model="provider.address"
              />
              <v-row class="mt-0">
                <v-col class="d-flex" style="gap: 10px">
                  <v-text-field
                    style="max-width: 150px"
                    outlined
                    clearable
                    dense
                    v-model="provider.zip_code"
                    :label="$t('zip_code_*')"
                    :rules="[rules.required]"
                  />

                  <v-autocomplete
                    style="max-width: 300px"
                    outlined
                    dense
                    :label="$t('country_*')"
                    v-model="provider.country_code"
                    :items="countries"
                    item-value="code"
                    item-text="name"
                    :loading="isLoadingCountries"
                    :rules="[rules.required]"
                    @change="countrySelected"
                  />

                  <v-autocomplete
                    dense
                    outlined
                    hide-details="auto"
                    :label="$t('city_*')"
                    v-model="provider.city_id"
                    :items="cities"
                    item-value="id"
                    item-text="name"
                    :loading="isLoadingCities"
                    :rules="[rules.required]"
                    :disabled="!provider.country_code"
                    :search-input.sync="search"
                  ></v-autocomplete> </v-col
              ></v-row>

              <div
                class="text-h4 d-flex justify-space-between"
                style="max-width: 250px"
              >
                <span class="text-h4">Supplier status</span>
                <v-switch
                  class="text-h4 mt-n1"
                  v-model="provider.is_active"
                  :label="`${provider.is_active ? 'Active' : 'Inactive'}`"
                  @change="activation"
                  color="success"
                >
                </v-switch>
              </div>

              <h4 class="text-h4 mb-7 mt-3">{{ $t("billing_information") }}</h4>
              <v-text-field
                dense
                outlined
                :label="$t('RC')"
                v-model="provider.RC"
                :rules="[
                  (value) =>
                    !provider.is_active || !!value || 'This area is required',
                ]"
              />
              <v-text-field
                dense
                outlined
                :label="$t('nif')"
                v-model="provider.NIF"
                :rules="[
                  (value) =>
                    !provider.is_active || !!value || 'This area is required',
                ]"
              />
              <v-text-field
                dense
                outlined
                :label="$t('ai')"
                v-model="provider.AI"
                :rules="[
                  (value) =>
                    !provider.is_active || !!value || 'This area is required',
                ]"
              />
              <v-row class="mt-0">
                <v-col class="d-flex" style="gap: 10px">
                  <v-autocomplete
                    style="max-width: 180px"
                    outlined
                    clearable
                    dense
                    v-model="provider.sourcing_type"
                    :items="['local', 'import']"
                    :label="$t('sourcing_type')"
                    multiple />

                  <v-autocomplete
                    dense
                    outlined
                    hide-details="auto"
                    :label="$t('payment_method')"
                    v-model="provider.payment_method_id"
                    :items="paymentMethods"
                    item-value="id"
                    item-text="name"
                    :loading="isLoadingMethods"
                    :rules="[
                      (value) =>
                        !provider.is_active ||
                        !!value ||
                        'This area is required',
                    ]" />

                  <v-text-field
                    dense
                    outlined
                    hide-details="auto"
                    v-model="provider.payment_term"
                    :label="$t('payment_term')"
                    type="number"
                    min="0"
                    :rules="[
                      (value) =>
                        !provider.is_active ||
                        !!value ||
                        'This area is required',
                    ]" /></v-col
              ></v-row>
              <v-text-field
                dense
                outlined
                hide-details="auto"
                :label="$t('rib')"
                v-model="provider.rib"
              />
            </v-flex>
            <v-flex xs6>
              <v-text-field
                class="text-h4 mt-15 mb-n3"
                outlined
                clearable
                dense
                :label="$t('email')"
                v-model="provider.email"
                :rules="[rules.email]"
              />
              <v-row class="mt-0">
                <v-col class="d-flex" style="gap: 10px">
                  <v-text-field
                    style="max-width: 300px"
                    outlined
                    clearable
                    dense
                    :label="$t('phone_1')"
                    v-model="provider.phone"
                    :rules="[rules.phone]"
                  />
                  <v-text-field
                    style="max-width: 300px"
                    outlined
                    clearable
                    dense
                    :label="$t('phone_2')"
                    v-model="provider.phone_2"
                    :rules="[rules.phone]"
                  />
                  <v-text-field
                    style="max-width: 300px"
                    outlined
                    clearable
                    dense
                    :label="$t('fax')"
                    v-model="provider.fax"
                  /> </v-col
              ></v-row>

              <h4 class="text-h4 mb-7 mt-4">Representative Information</h4>

              <v-text-field
                outlined
                clearable
                dense
                :label="$t('representative_name')"
                v-model="provider.cor_name"
                :rules="[
                  (value) =>
                    !provider.is_active || !!value || 'This area is required',
                ]"
              />
              <v-text-field
                outlined
                clearable
                dense
                :label="$t('representative_email')"
                v-model="provider.cor_email"
                :rules="[
                  (value) =>
                    !provider.is_active || !!value || 'This area is required',
                  rules.email,
                ]"
              />

              <v-text-field
                outlined
                dense
                v-model="provider.cor_phone"
                :label="$t('representative_phone')"
                :rules="[
                  (value) =>
                    !provider.is_active || !!value || 'This area is required',
                  rules.phone,
                ]"
              />

              <h4 class="text-h4 mb-4 mt-4">Purchase Information</h4>

              <v-row class="mt-0">
                <v-col class="d-flex" style="gap: 10px">
                  <v-text-field
                    style="max-width: 300px"
                    outlined
                    clearable
                    dense
                    type="number"
                    min="0"
                    v-model="provider.shipping_days"
                    :label="$t('shipping_days')"
                    :rules="[
                      (value) =>
                        !provider.is_active ||
                        !!value ||
                        'This area is required',
                    ]"
                  />
                  <v-text-field
                    style="max-width: 300px"
                    dense
                    outlined
                    hide-details="auto"
                    v-model="provider.min_franco_quantity"
                    :rules="[]"
                    type="number"
                    min="0"
                    hide-spin-buttons
                    :label="$t('mini_franco_quantity')"
                  ></v-text-field>

                  <v-text-field
                    dense
                    outlined
                    hide-details="auto"
                    v-model="provider.min_franco_value"
                    :rules="[]"
                    type="number"
                    min="0"
                    :label="$t('mini_franco_value')"
                    hide-spin-buttons
                  /> </v-col
              ></v-row>

              <h4 class="text-h4 mb-7 mt-4">Internal note</h4>

              <v-textarea
                outlined
                v-model="provider.internal_notes"
                dense
                :label="$t('internal_notes')"
              />
            </v-flex>
          </div>
        </v-tab-item>
      </v-tabs-items>
      <div></div>

      <div class="mt-10">
        <v-btn depressed color="primary" @click="save(provider)" class="me-2">
          Save Changes
        </v-btn>
        <v-btn text @click="close()"> Cancel </v-btn>
      </div>
    </v-container>
  </v-form>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import providerService from "@/store/services/provider-service";

export default {
  mixins: [validationRules],

  computed: {
    ...mapGetters({
      isLoadingWilayas: "wilayas/isLoadingWilayas",
      isLoadingCities: "cities/isLoadingCities",
      isLoadingCountries: "countries/isLoadingCountries",
      isLoadingMethods: "paymentMethods/isLoadingMethods",
      isLoadingShelves: "categories/isLoadingCategories",
      statuses: "statuses/listStatuses",
      countries: "countries/listCountries",
      paymentMethods: "paymentMethods/listMethods",
      shelves: "categories/listShelves",
      wilayas: "wilayas/listWilayas",
      cities: "cities/listCities",
    }),
    provider: {
      get() {
        return this.$store.state.providers.editedProvider;
      },
      set(value) {
        this.$store.commit("providers/EDITED_PROVIDER", value);
      },
    },
  },
  watch: {
    search: debounce(function (searchQuery) {
      if (this.isLoadingCities) return;
      this.$store.dispatch("cities/search", {
        countryCode: this.provider.country_code,
        searchQuery: searchQuery,
      });
    }, 1000),
  },

  data() {
    return {
      tab: true,
      search: null,
      is_valid: false,
    };
  },
  methods: {
    activation() {
      this.validate();
      if (!this.is_valid && this.provider.is_active == true) {
        console.log("ok");
        this.$store.dispatch(
          "alerts/info",
          "To activate a supplier , Please fill in the required filed"
        );
      }
    },
    async loadShelves() {
      try {
        await this.$store.dispatch("categories/listShelves");
      } catch (error) {
        if (error.response?.status == "401") {
          await this.$store.dispatch("logout");
        }
        await this.$store.dispatch(
          "alerts/error",
          error.response?.data?.message
        );
      }
    },
    countrySelected() {
      this.$store.commit("cities/RESET_CITIES");
      this.provider.city_id = null;
      this.$store.dispatch("cities/search", {
        countryCode: this.provider.country_code,
        searchQuery: "a",
      });
    },
    validate() {
      this.$refs.form.validate();
    },
    async save(provider) {
      if (this.provider.is_active == null) this.provider.is_active = false;
      this.validate();
      if (this.is_valid) {
        try {
          if (provider.id) {
            await this.$store.dispatch("providers/update", provider);
            await this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
          } else {
            await this.$store.dispatch("providers/add", provider);
            await this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
          }

          this.$router.push("/setup/providers");
        } catch (error) {
          await this.$store.dispatch(
            "alerts/error",
            error.response?.data?.message
          );
        }
      }
    },
    close() {
      this.$router.push("/setup/providers");
      this.$store.commit("SET_PAGE_SUBTITLE", "");
      this.$store.commit("SET_SUBTITLE_ID", "");
    },
    async loadCountries() {
      try {
        await this.$store.dispatch("countries/list");
      } catch (error) {
        if (error.response?.status == "401") {
          await this.$store.dispatch("logout");
        }
        await this.$store.dispatch(
          "alerts/error",
          error.response?.data?.message
        );
      }
    },
    async loadPaymentMethods() {
      try {
        await this.$store.dispatch("paymentMethods/list", "provider");
      } catch (error) {
        if (error.response?.status == "401") {
          await this.$store.dispatch("logout");
        }
        await this.$store.dispatch(
          "alerts/error",
          error.response?.data?.message
        );
      }
    },
    init() {
      this.loadCountries();
      this.loadShelves();
      this.loadPaymentMethods();
    },
  },

  async created() {
    console.log("ok");
    await this.init();
    const id = this.$route.params.id;
    console.log(id);
    if (id) {
      const providerEntity = await providerService.show({
        id,
      });
      this.$store.commit("providers/EDITED_PROVIDER", providerEntity);
      this.$store.commit("cities/APPEND_CITIES", providerEntity.city);
      this.$store.commit("SET_PAGE_SUBTITLE", providerEntity.id);
      this.$store.commit("SET_SUBTITLE_ID", "ID:");
    }
  },
};
</script>
<style >
.swal2-icon.swal2-info {
  border-color: #ff5252;
  color: #ff5252;
}
</style>
